import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import "../../css/custom-css/custom-products-services.css"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/services/banner-service.png"
import Seo from "../../components/seo"

const HPVHighRisk = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="HPV DNA High Risk Test" />
      <Header servicesPage />

      <BannerHeader
        background={bg}
        title="HPV DNA High Risk Test"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <StaticImage
                      src="../../images/services/service-highrisk-1.png"
                      layout="fullWidth"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-4">
                    <StaticImage
                      src="../../images/services/service-highrisk-2.png"
                      layout="fullWidth"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">HPV DNA High Risk Test</h4>
                    <p className="ml-4">
                      <ul>
                        <li>
                          The oncogenic types of HPV are classified as high
                          risk, and include HPV 16 and 18.
                        </li>
                        <li>
                          Other types are considered low risk, as they do not
                          appear to cause cancer.
                        </li>
                        <li>
                          According to the World Health Organization (WHO), HPV
                          is the second biggest cause of female cancer mortality
                          worldwide, claiming about 250,000 lives annually. In
                          Europe alone, the disease claims about 15,000 lives
                          each year. It is estimated that nearly 70% of cervical
                          cancers are caused by HPV types 16 and 18.
                        </li>
                        <li>
                          More than 200 different HPV types have been identified
                          based on differences in genome sequences.
                        </li>
                        <li>
                          Among these HPV types, a subset of 19 HPV types are
                          considered as high/moderate high risk for causing
                          lesions of the cervical mucosa and leads to cervical
                          cancer in women.
                        </li>
                        <li>
                          HPV DNA High Risk Test is a PCR based qualitative
                          detection of DNA from Human papillomavirus (HPV)
                          genotypes 16, 18, 31, 33, 35, 39, 45, 51, 52, 53, 56,
                          58, 59, 66, 68 and specific identify HPV 16 and HPV
                          18, the two most dangerous HPV genotypes, from dry
                          swab cervical sample.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default HPVHighRisk
